<template>
  <div>
    <div class="text-h6 font-weight-bold">新增報修</div>
    <v-row no-gutters class="mt-5">
      <v-col cols="12" md="6">
        <v-form>
          <v-text-field
            outlined
            background-color="white"
            label="標題"
            v-model="ticket.name"
          />
          <v-text-field
            outlined
            background-color="white"
            label="狀況說明"
            v-model="ticket.content"
          />
          <v-text-field
            outlined
            background-color="white"
            label="申告人"
            v-model="ticket.reporter"
          />
          <v-text-field
            outlined
            background-color="white"
            label="email"
            v-model="ticket.reporter_email"
          />
          <v-text-field
            outlined
            background-color="white"
            label="電話"
            v-model="ticket.reporter_telephone"
          />
          <v-text-field
            outlined
            background-color="white"
            label="縣市"
            v-model="ticket.area_id"
          />
          <v-text-field
            outlined
            background-color="white"
            label="學校"
            v-model="ticket.school_id"
          />
          <v-file-input
            outlined
            background-color="white"
            label="上傳照片"
            outlined
            multiple
            v-model="ticket.attached_files"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="primary" dark label>
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ ticket.attached_files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
          <div class="d-flex">
            <v-spacer />
            <v-btn color="primary" large @click="createTicket">送出</v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Ticket from "@/api/Ticket";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      ticket: {},
    };
  },
  // mounted() {
  //   //check for Navigation Timing API support
  //   if (window.performance) {
  //     console.info("window.performance works fine on this browser");
  //   }
  //   console.info(performance.navigation.type);
  //   if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
  //     console.info("This page is reloaded");
  //   } else {
  //     console.info("This page is not reloaded");
  //   }
  // },
  methods: {
    ...mapActions({
      setLoadingOverlay: "settings/setLoadingOverlay",
    }),
    async createTicket() {
      try {
        this.setLoadingOverlay(true);
        let formData = new FormData();
        for (const [key, value] of Object.entries(this.ticket)) {
          if (key === "attached_files") {
            this.ticket.attached_files.forEach((element, index) => {
              formData.append("attached_files[" + index + "]", element);
            });
          } else formData.append(key, value);
        }
        formData.append("process_description", "");
        await Ticket.create(formData);
        Swal.fire({
          title: "建立成功",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          width: 250,
        });
        this.ticket = {};
      } catch (err) {
        console.error(err.response);
        Swal.fire({
          title: "建立失敗",
          icon: "error",
          confirmButtonColor: "#3085d6",
          width: 300,
        });
      } finally {
        this.setLoadingOverlay(false);
      }
    },
  },
};
</script>
